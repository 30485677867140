const communities = [
  {
    id: 0,
    loc: "/projects/thorncliffe",
    title: "Thorncliffe",
    desc: "Basement, Secondary Suite",
    testimonial:
      "We were first-timers when it came to drywall. The taping and corner bead tools provided by Kor-Mos Manufacturing enabled us to complete our project to a high standard; the tools greatly reduced and simplified the process of taping, mudding, and sanding.",
    media: [
      {
        id: 0,
        src: "/Thorncliffe/1.jpg",
        alt: "Thornecliffe Image",
        image: true,
      },
      {
        id: 1,
        src: "/Thorncliffe/2.jpg",
        alt: "Thornecliffe Image",
        image: true,
      },
      {
        id: 2,
        src: "/Thorncliffe/3.jpg",
        alt: "Thornecliffe Image",
        image: true,
      },
      {
        id: 3,
        src: "/Thorncliffe/4.jpg",
        alt: "Thornecliffe Image",
        image: true,
      },
    ],
  },
  {
    id: 1,
    loc: "/projects/coral-springs",
    title: "Coral Springs",
    desc: "Basement, Development",
    testimonial:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
    media: [
      {
        id: 0,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 1,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 2,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 3,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 4,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 5,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
    ],
  },
  {
    id: 2,
    loc: "/projects/sunnyside",
    title: "Sunnyside",
    desc: "Garage",
    testimonial:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
    media: [
      {
        id: 0,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 1,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 2,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 3,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 4,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 5,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
    ],
  },
  {
    id: 3,
    loc: "/projects/huntington",
    title: "Huntington",
    desc: "Main Floor Renovation",
    testimonial:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
    media: [
      {
        id: 0,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 1,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 2,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 3,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 4,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 5,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
    ],
  },
  {
    id: 4,
    loc: "/projects/north-haven",
    title: "North Haven",
    desc: "Basement, Secondary Suite",
    testimonial:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
    media: [
      {
        id: 0,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 1,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 2,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 3,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 4,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 5,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
    ],
  },
  {
    id: 5,
    loc: "/projects/royal-oak",
    title: "Royal Oak",
    desc: "Walk-out Basement Renovation",
    testimonial:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
    media: [
      {
        id: 0,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 1,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 2,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 3,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 4,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
      {
        id: 5,
        src: "/Drywall-taping-tool.jpg",
        alt: "Drywall taping tool",
        image: true,
      },
    ],
  },
];

export { communities };
