import { Route, Routes } from "react-router-dom";
import Project from "./Pages/Project";

import { communities } from "./Project_data";

function ProjectRoutes() {
    return (
        <Routes>
        {communities.map((community, idx) => (
          <Route key = {idx} path={community.loc} element={<Project key={community.id} {...community} />} />
        ))}
      </Routes>
    )
}

export default ProjectRoutes