import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFillDrip } from "@fortawesome/free-solid-svg-icons";

import React from "react"

function About() {

  React.useEffect(() => {
    //const lineart = document.querySelectorAll(".line-art1");

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          entry.target.classList.toggle("show", entry.isIntersecting)
          if (entry.isIntersecting) observer.unobserve(entry.target)
        })
      },
      { threshold: 0.25, }
    )

    //observer.observe(lineart[0]);


    }, []
  );

  return (
    <body>
      <div className="about-kormos"> Kor-Mos Manufacturing &nbsp;<FontAwesomeIcon icon={faFillDrip} /></div>
      <p className="about-kormos-par">
        Kor-Mos Manufacturing has been supplying, and helping customers
        throughout the Calgary area with home improvement products, supplies,
        and general support since 1999. We take pride in serving both retail
        customers, such as homeowners and renters, and professional customers,
        such as builders, contractors, and repairmen. Our experienced team of
        home improvement professionals are committed to providing guidance, and
        inspiration. <br />
        <br />
        At Kor-Mos Manufacturing, we’re interested in helping anyone involved in
        building or remodeling, no matter the size of the project. Our priority
        is to help you in any way we can to see your project through to
        completion.
      </p>      
    </body>
  );
}

export default About;
