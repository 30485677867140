import { CartContext } from "../Context/CartContext";
import { useContext } from "react";
import { getProductData } from "./Products";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus } from "@fortawesome/free-solid-svg-icons";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

import formatCurrency from "../Utilities/formatCurrency";

function CartProduct(props) {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);

    return (
        <>
        <div className="product-container">
            <div className="product-col-1">
            <h3>{productData.title}</h3>
            <div><FontAwesomeIcon onClick={() => cart.removeOneFromCart(id)} className="cart-minus" icon={faSquareMinus} /> <span className="cart-quant">&nbsp;{quantity} </span> <span>in Cart &nbsp;</span> <span><FontAwesomeIcon onClick={() => cart.addOneToCart(id)} className="cart-plus" icon={faSquarePlus} /></span></div>
            <p>{formatCurrency((quantity * productData.price).toFixed(2))}</p>
            </div>
            <div className="product-col-2">
                <img className="product-cont-images" src = {productData.src} alt="">
                </img>
                </div>           
            </div>
            <div className="remove-from-cart" onClick={() => cart.deleteFromCart(id)}> Remove from Cart</div>
            <hr></hr>
        </>
    )
}

export default CartProduct;