import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// components
import Header from "./Components/HeaderComponent/Header";
import Footer from "./Components/FooterComponent/Footer";

import HomePage from "./Components/Pages/HomePage";
import TapingTool from "./Components/Pages/TapingTool";
import CornerBeadTool from "./Components/Pages/CornerBeadTool";

import About from "./Components/Pages/About";
import Projects from "./Components/Pages/Projects";
import Contact from "./Components/Pages/Contact";

import ProjectRoutes from "./Components/ProjectRoutes";

// assets
import "./Assets/css/default.css";
import "./Assets/css/HomePage.css";
import "./Assets/css/Footer.css";
import "./Assets/css/Header.css";
import "./Assets/css/TapingTool.css";
import "./Assets/css/CornerBeadTool.css";
import "./Assets/css/About.css";
import "./Assets/css/Projects.css";
import "./Assets/css/Project.css";
import "./Assets/css/Contact.css";

import { Route, Routes } from "react-router-dom";

// shopping cart
import CartProvider from "./Context/CartContext";

function App() {

  return (
    <CartProvider>
    <body className="page-container">
      <div className="content-wrap">
      <Header />
      <ProjectRoutes/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/taping-tool" element={<TapingTool/>}/>
        <Route path="/corner-bead-tool" element={<CornerBeadTool/>}/>
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      </div>
      <Footer />
    </body>
    </CartProvider>
  );
}

export default App;
