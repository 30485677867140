import ReactPlayer from "react-player";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

function Project({ title, desc, testimonial, media }) {
    return (
        <body>
            <div className="project-back-button-cont">
            
            <div className="project-back-button">
            <Link to="/projects">
            <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;Projects
            </Link>
            </div>
            </div>
            <div className="project-title">
                {title}
            </div>
            <p className="project-desc">
                {desc}
                <br />
            </p>
            <div className="card-testimonial">
            <p className="project-testimonial">
                "{testimonial}"
            </p>
            </div>
            <div className="project-photo-grid">
                {media.map((image) =>
                    image.image ? (
                        <img src={image.src} key={image.id} alt={image.alt} />
                    ) : (
                        <ReactPlayer
                            className="CFwheel"
                            url="CFRP_Wheels.MP4"
                            width="100%"
                            height="100%"
                            controls={true}
                        />
                    )
                )}
            </div>
        </body>
    )
}

export default Project