const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
    currency: "CAD",
    style: "currency",
})

function formatCurrency(number) {
    return (
      CURRENCY_FORMATTER.format(number)
    );
  }
  
  export default formatCurrency;