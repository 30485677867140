import React from "react";

function Footer() {
  return (
    <footer>
      <div className="footerContainer">
        <div className="mail-to">
          kormos@telusplanet.net
        </div>
        <div className="p-number">
          +1 (403) 371-1128
        </div>
        <div>
          <a className="facebook-link" href="https://www.facebook.com/KorMos.Manufacturing/" target="_blank" rel="noreferrer"> f </a>
        </div>
        <div className="copyright">
          Copyright <span>&#169;</span> 2024 Kor-Mos Manufacturing
        </div>
        <div className="web-design">
         Powered by <a className="web-design-link" href="https://www.simplewebsitedesign.ca" target="_blank" rel="noreferrer">simplewebsitedesign.ca</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
