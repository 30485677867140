import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { communities } from "../Project_data";

function Projects() {
  return (
    <body>

      <div className="projects"> Projects &nbsp;<FontAwesomeIcon icon={faHammer} />
      </div>
      <p className="projects_par">
        Home improvement projects throughout the Calgary area. 
      </p>
      
      <div className="community-list-cont">
      
      {communities.map((community) => <Link to={community.loc}> <div className="card-proj"><div className="community-list"><div className="community-title"> {community.title}</div> <div className="community-desc">{community.desc}</div></div></div></Link>)}
      </div>
    </body>
  );
}

export default Projects;