import React, { useState } from "react";

import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contact() {
  //Email form
  const [emailForm, setEmailForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  //Result if message was sent or not
  const [result, setResult] = useState("");

  //Status of while message is being sent
  const [status, setStatus] = useState("Send");

  function resetEmailForm() {
    setEmailForm({ name: "", email: "", subject: "", message: "" });
  }

  function handleEmailFormChange(event) {
    setEmailForm((prevEmailData) => {
      return {
        ...prevEmailData,
        [event.target.name]: event.target.value,
      };
    });

    if (result.length > 0) {
      setResult("");
    }
  }

  const handleSubmit = async (e) => {
    setResult('');
    e.preventDefault();
    setStatus('Sending...');

    const { name, email, subject, message } = e.target.elements;

    let details = {
        name: name.value,
        email: email.value,
        subject: subject.value,
        message: message.value,
    };

    //https://kormos-manufacturing-a58bf1aa475d.herokuapp.com/
    //http://localhost:5000/send

    try {
        let response = await fetch('https://kormos-manufacturing-a58bf1aa475d.herokuapp.com/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(details),
        });
        setStatus('Send');
        let result = await response.json();

        if (result.status === 'success') {
            setResult('Message Sent!');
            resetEmailForm();
        } else if (result.status === 'fail') {
            alert('Message failed to send.');
        }
    } catch (error) {
        console.error(error);
        setStatus('Send');
        setResult('Issues with submitting message.');
    }
};

  return (
    <body>
      <div className="contact">
        {" "}
        Contact Us &nbsp;
        <FontAwesomeIcon icon={faComments} />
      </div>
      <p className="contact-par">
        We proudly serve the people of Calgary, and area. And now offer shipping
        anywhere in the world. Contact us with any questions or requests
        regarding our supplies for home repair and renovation. We want to hear
        from you and how we can help your construction needs.
      </p>
      <div className="contact-form-cont">
        <form id="contact-form" className="contact-form" onSubmit={handleSubmit} method='POST'>
          <input
            placeholder="Name*"
            type="text"
            name="name"
            required={true}
            value={emailForm.name}
            onChange={handleEmailFormChange}
          />
          <input
            placeholder="Email*"
            type="email"
            name="email"
            required={true}
            value={emailForm.email}
            onChange={handleEmailFormChange}
          />
          <input
            placeholder="Subject"
            type="subject"
            name="subject"
            required={false}
            value={emailForm.subject}
            onChange={handleEmailFormChange}
          />
          <textarea
            maxLength={300}
            placeholder="Message (max 300 characters)*"
            name="message"
            required={true}
            value={emailForm.message}
            onChange={handleEmailFormChange}
          />
          <button className="submit-button" type="submit">
            {status}
          </button>
          <h4>{result}</h4>
        </form>
      </div>
    </body>
  );
}

export default Contact;
