import React, { useState } from "react";
import ReactPlayer from "react-player";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus } from "@fortawesome/free-solid-svg-icons";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import formatCurrency from "../../Utilities/formatCurrency"

import { productsArray } from "../Products";

import { CartContext } from "../../Context/CartContext";
import { useContext } from "react";

function HomePage() {

  const cart = useContext(CartContext);
  const productTtQuantity = cart.getProductQuantity(productsArray[0].id);
  const productCbQuantity = cart.getProductQuantity(productsArray[1].id);
  const productBsQuantity = cart.getProductQuantity(productsArray[2].id);

  React.useEffect(() => {
    const drywalltools = document.querySelectorAll(".drywall-tools");
    const tapingtooltitle = document.querySelectorAll(".taping-tool-title");
    const tapingtooltitleunder = document.querySelectorAll(".taping-tool-title-under");
    const tapingtoolimage = document.querySelectorAll(".taping-tool-image");
    const tapingtooldesc = document.querySelectorAll(".taping-tool-desc");
    const cornerbeadtitle = document.querySelectorAll(".corner-bead-tool-title");
    const cornerbeadimage = document.querySelectorAll(".corner-bead-tool-image");
    const cornerbeaddesc = document.querySelectorAll(".corner-bead-tool-desc");
    const mainvideo = document.querySelectorAll(".vid-cont");

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          entry.target.classList.toggle("show", entry.isIntersecting)
          if (entry.isIntersecting) observer.unobserve(entry.target)
        })
      },
      { threshold: 0, }
    )

    observer.observe(drywalltools[0]);
    observer.observe(tapingtooltitle[0]);
    observer.observe(tapingtooltitleunder[0]);
    observer.observe(tapingtoolimage[0]);
    observer.observe(tapingtooldesc[0]);
    observer.observe(cornerbeadtitle[0]);
    observer.observe(cornerbeadimage[0]);
    observer.observe(cornerbeaddesc[0]);
    observer.observe(mainvideo[0]);
  }, []
  );

  //Brush Saver Image Toggling Start

  const [count, setCount] = useState(0);

  const [touchStart, setTouchStart] = useState(0);

  const [touchEnd, setTouchEnd] = useState(0);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (count === 0 && touchStart - touchEnd > 50) {
      setCount(count + 1);
    }
    if (count === 1 && touchStart - touchEnd < -50) {
      setCount(count - 1);
    }
  }

  //Brush Saver Image Toggling End

  return (
    <body>
      <div className="title-cont">
      <div className="bg-image">
        <img className="basement-plan" alt="Basement Plan" src="Basement_Plan.PNG"></img>
      </div>
      <div className="box-art1"><img alt="Corner Bead Tool Logo" className="corner-bead-tool-logo-image" src="corner-bead-tool.svg"></img></div>
      <div className="box-art2"> <img alt="Taping Tool Logo" className="taping-tool-logo-image" src="taping-tool.svg"></img></div>
      <div className="box-art3"> <img alt="Tape Roll Logo" className="tape-roll-logo-image" src="tape-roll.svg"></img></div>
      <div className="home-improv-cont">
        <p className="home-improv-par">Home Improvement Tools.</p>
        <div className="patented-cont">Patented and reliable.</div>
      </div>
      <div className="box-art4"> <img alt="Brush Saver Logo" className="brush-saver-logo-image" src="brush-saver.svg"></img></div>
      <div className="box-art5"> <img alt="Corner Bead Logo" className="corner-bead-logo-image" src="corner-bead.svg"></img></div>
      </div>
      <div className="drywall-tools">Drywall Tools</div>
        <div className="prod-container">
          <div className="taping-tool-cont">
            <div className="taping-tool-title">{productsArray[0].title}:</div>
            <div className="taping-tool-title-under">(corner bead tool included)</div>
            <div className="taping-tool-image-cont">
              <img
                src={productsArray[0].src}
                alt="Taping Tool"
                className="taping-tool-image"
              />
            </div>
            <div className="taping-tool-desc">
              <span>{formatCurrency(productsArray[0].price)}</span>
              <span>
                <Link to="/taping-tool">Instructions</Link>
              </span>
              <span>{productTtQuantity === 0 ? (<div onClick={() => cart.addOneToCart(productsArray[0].id)} className="add-to-cart">Add to Cart</div>) :
                <div><FontAwesomeIcon onClick={() => cart.removeOneFromCart(productsArray[0].id)} className="cart-minus" icon={faSquareMinus} /> <span className="cart-quant">&nbsp;{productTtQuantity} </span> <span>in Cart &nbsp;</span> <span><FontAwesomeIcon onClick={() => cart.addOneToCart(productsArray[0].id)} className="cart-plus" icon={faSquarePlus} /></span></div>
              }</span>
            </div>
          </div>
          <div className="corner-bead-tool-cont">
            <div className="corner-bead-tool-title">{productsArray[1].title}:</div>
            <div className="corner-bead-tool-title-under">&nbsp;</div>
            <div className="corner-bead-tool-image-cont">
              <img
                src={productsArray[1].src}
                alt="Corner Bead Tool"
                className="corner-bead-tool-image"
              />
            </div>
            <div className="corner-bead-tool-desc">
              <span>{formatCurrency(productsArray[1].price)}</span>
              <span>
                <Link to="/corner-bead-tool">Instructions</Link>
              </span>
              <span>{productCbQuantity === 0 ? (<div onClick={() => cart.addOneToCart(productsArray[1].id)} className="add-to-cart">Add to Cart</div>) :
                <div><FontAwesomeIcon onClick={() => cart.removeOneFromCart(productsArray[1].id)} className="cart-minus" icon={faSquareMinus} /> <span className="cart-quant">&nbsp;{productCbQuantity} </span> <span>in Cart &nbsp;</span> <span><FontAwesomeIcon onClick={() => cart.addOneToCart(productsArray[1].id)} className="cart-plus" icon={faSquarePlus} /></span></div>
              }</span>
            </div>
          </div>
        </div>
        <div className="vid-title">Video:</div>
        <div className="vid-cont">
            <ReactPlayer
              url="Drywall Tools V1.mp4"
              width="100%"
              height="auto"
              controls={true}
            />
        </div>
    </body>
  );
}

export default HomePage;
