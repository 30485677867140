import ReactPlayer from "react-player";
import formatCurrency from "../../Utilities/formatCurrency";

import { productsArray } from "../Products";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus } from "@fortawesome/free-solid-svg-icons";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import { CartContext } from "../../Context/CartContext";
import { useContext } from "react";

function TapingTool() {
  const cart = useContext(CartContext);
  const productTtQuantity = cart.getProductQuantity(productsArray[0].id);

  return (
    <body>
      <div className="tt-button-cont">
        <div className="tt-back-button">
          <Link to="/">
            <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;Home Page
          </Link>
        </div>

        <div className="tt-forward-button">
          <Link to="/corner-bead-tool">
            Corner Bead Tool &nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
      <div className="taping-tool-prod-title"> Drywall Taping Tool</div>
      <img
        src="Drywall-taping-tool2.png"
        alt="Taping Tool"
        className="taping-tool-prod-image"
      />
      <div className="card-req">
      <div className="taping-tool-prod-req">Required Materials</div>
      <div className="taping-tool-prod-req-list-cont">
      <ul className="taping-tool-prod-req-list">
        <li>Drywall tape</li>
        <li>Putty knife</li>
        <li>Inside corner putty knife (recommended)</li>
        <li>Drywall mud pan</li>
        <li>Large bucket</li>
        <li>All-purpose drywall compound</li>
        <li>Mud mixer (recommended)</li>
        <li>Exacto knife</li>
      </ul>
      </div>
      </div>
      <div className="taping-tool-prod-instr">Instructions</div>
      <div className="taping-tool-prod-instr-list">
        <div className="card">
        <img
          src="/Taping-tool-instructions/Mixing.jpg"
          alt="Mixing Drywall Mud"
          className="tt-mixing-mud"
        />
        <li>
          <strong>1)</strong> Mix drywall compound. Ensure mud is wet enough; add water if too dry.
        </li>
        </div>
        <div className="card">
        <img
          src="/Taping-tool-instructions/Feeding-Tape.jpg"
          alt="Feeding Drywall Tape"
          className="tt-feeding-tape"
        />
        <li>
          <strong>2)</strong> Feed tape through taping tool. Ensure correct side is facing up (ridge up). Use exacto knife to clear slots of dried mud, as needed.
        </li>
        </div>
        <div className="card">
        <img
          src="/Taping-tool-instructions/Filling-Bucket.jpg"
          alt="Filling Drywall Tool"
          className="tt-filling-tool"
        />
        <li>
          <strong>3)</strong> Fill drywall taping tool with compound.
        </li>
        </div>
        <div className="card">
        <img
          src="/Taping-tool-instructions/Large-Bucket.jpg"
          alt="Placing Large Bucket"
          className="tt-large-bucket"
        />
        <li>
          <strong>4)</strong> Place large bucket in front of taping tool.
        </li>
        </div>
        <div className="card">
        <img
          src="/Taping-tool-instructions/Feeding-Bucket.jpg"
          alt="Feeding Tape into Large Bucket"
          className="tt-feeding-bucket"
        />
        <li>
          <strong>5)</strong> Pull and feed tape into bucket. Length should be longer than seam, or multiple.
        </li>
        </div>
        <div className="card">
        <img
          src="/Taping-tool-instructions/Ripping-Tape.jpg"
          alt="Ripping Tape"
          className="tt-ripping-tape"
        />
        <li>
          <strong>6)</strong> Rip tape near exit slot.
        </li>
</div>
<div className="card">

        <img
          src="/Taping-tool-instructions/Moving-Bucket.jpg"
          alt="Moving Bucket and Feeding Drywall Tape"
          className="tt-moving-bucket"
        />
        <li>
          <strong>7)</strong> Move bucket to taping location and feed tape onto
          seam. Rip tape at a length of seam, or slightly greater.
        </li>
        </div>
        <div className="card">

        <img
          src="/Taping-tool-instructions/Flatten-Tape.jpg"
          alt="Flatten Tape and Smooth Seam"
          className="tt-flatten-tape"
        />
        <li>
          <strong>8)</strong> Flatten tape and smooth drywall compound using putty knife. Scrape excess mud into mud pan.
        </li>
        </div>
        <div className="card">
          <div className="tt-taping-complete-cont">
        <img
          src="/Taping-tool-instructions/Taping-Complete1.jpg"
          alt="Drywall Taping Complete 1"
          className="tt-taping-complete1"
        />
        <img
          src="/Taping-tool-instructions/Taping-Complete2.jpg"
          alt="Drywall Taping Complete 2"
          className="tt-taping-complete2"
        />
        </div>
        <li>
          <strong>9)</strong> Continue feeding tape from bucket for additional
          seams, refilling as necessary. Voila.
        </li>
        </div>
        <div className="card">
        <div className="tt-end-of-roll-cont">
        <img
          src="/Taping-tool-instructions/End-of-Roll.jpg"
          alt="Masking Tape for End of Roll 1"
          className="tt-end-of-roll1"
        />
        <img
          src="/Taping-tool-instructions/End-of-Roll2.jpg"
          alt="Masking Tape for End of Roll 2"
          className="tt-end-of-roll2"
        />
        </div>
        <li>
          <strong>10)</strong> If at the end of drywall tape and there is still
          compound in the bucket, use masking tape to join end of roll with new
          roll. Discard masking tape section.
        </li>
        </div>
        <div className="card">
        <img
          src="/Taping-tool-instructions/Clean.jpg"
          alt="Clean Drywall Taping Tool"
          className="tt-clean"
        />
        <li>
          <strong>11)</strong> Clean taping tool; pour excess mud back into
          original container and ensure taping tool slots are clear for future
          use. Cover original mud container so that the mud doesn't dry.
        </li>
        </div>
      </div>
      <div className="taping-tool-prod-vid-cont">Video</div>
      <div className="taping-tool-prod-vid">
        <ReactPlayer
          url="Drywall Taping Tool V1.mp4"
          width="100%"
          height="auto"
          controls={true}
        />
      </div>
    </body>
  );
}

export default TapingTool;
