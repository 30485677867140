import ReactPlayer from "react-player";
import formatCurrency from "../../Utilities/formatCurrency";

import { productsArray } from "../Products";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus } from "@fortawesome/free-solid-svg-icons";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import { CartContext } from "../../Context/CartContext";
import { useContext } from "react";

function CornerBeadTool() {
  const cart = useContext(CartContext);
  const productCbQuantity = cart.getProductQuantity(productsArray[1].id);

  return (
    <body>
      <div className="cb-back-button-cont">
        <div className="cb-back-button">
          <Link to="/taping-tool">
            <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;Taping Tool
          </Link>
        </div>
      </div>

      <div className="cornerbead-tool-prod-title">
        {" "}
        Drywall Corner Bead Tool
      </div>
      <img
        src="Drywall-corner-bead-tool2.png"
        alt="Corner Bead Tool"
        className="cornerbead-tool-prod-image"
      />
      <div className="card-req">
        <div className="cornerbead-tool-prod-req">Required Materials</div>
        <div className="cornerbead-tool-prod-req-list-cont">
          <ul className="cornerbead-tool-prod-req-list">
            <li>Drywall corner beads</li>
            <li>Tin snips (or scissors)</li>
            <li>Putty knife</li>
            <li>Drywall mud pan</li>
            <li>All-purpose drywall compound</li>
            <li>Mud mixer (recommended)</li>
            <li>Exacto knife</li>
          </ul>
        </div>
      </div>
      <div className="cornerbead-tool-prod-instr">Instructions</div>
      <div className="cornerbead-tool-prod-instr-list">
        <div className="card">
          <img
            src="/Cornerbead-tool-instructions/Mixing.jpg"
            alt="Mixing Drywall Mud"
            className="cb-mixing-mud"
          />
          <li>
            <strong>1)</strong> Mix drywall compound. Ensure that mud is wet
            enough; add water if too dry.
          </li>
        </div>
        <div className="card">
          <img
            src="/Cornerbead-tool-instructions/Cutting.jpg"
            alt="Cutting Corner Bead to Length"
            className="cb-cutting"
          />
          <li>
            <strong>2)</strong> Cut corner bead to length.
          </li>
        </div>
        <div className="card">
          <img
            src="/Cornerbead-tool-instructions/Filling-Tool.jpg"
            alt="Filling Corner Bead Tool with Mud"
            className="cb-filling-tool"
          />
          <li>
            <strong>3)</strong> Fill corner bead tool with compound.
          </li>
        </div>
        <div className="card">
          <div className="cb-feeding-cont">
            <img
              src="/Cornerbead-tool-instructions/Feeding-Corner-Bead1.jpg"
              alt="Feeding Corner Bead through Tool 1"
              className="cb-feeding1"
            />
            <img
              src="/Cornerbead-tool-instructions/Feeding-Corner-Bead2.jpg"
              alt="Feeding Corner Bead through Tool 2"
              className="cb-feeding2"
            />
          </div>
          <li>
            <strong>4)</strong> Feed corner bead through corner bead tool. Use
            exacto knife to clear slots of dried mud, as needed.
          </li>
        </div>
        <div className="card">
          <img
            src="/Cornerbead-tool-instructions/Applying-Corner-Bead.jpg"
            alt="Applying Corner Bead"
            className="cb-applying"
          />
          <li>
            <strong>5)</strong> Apply corner bead to desired edge.
          </li>
        </div>
        <div className="card">
          <img
            src="/Cornerbead-tool-instructions/Smoothing-Corner-Bead.jpg"
            alt="Smoothing Corner Bead"
            className="cb-smoothing"
          />
          <li>
            <strong>6)</strong> Flatten corner bead and smooth drywall compound
            using putty knife. Scrape excess mud into mud pan.
          </li>
        </div>
        <div className="card">
          <img
            src="/Cornerbead-tool-instructions/Corner-Beads.jpg"
            alt="Mudded Corner Beads"
            className="cb-after"
          />
          <li>
            7) Continue cutting corner beads to length and feeding them through
            the corner bead tool.
          </li>
        </div>
        <div className="card">
          <img
            src="/Cornerbead-tool-instructions/Clean.jpg"
            alt="Clean Corner Bead Tool"
            className="cb-clean"
          />
          <li>
            8) Clean corner bead tool; pour excess mud back into original
            container and ensure slots are clear for future use. Cover original
            mud container so that the mud doesn't dry.
          </li>
        </div>
      </div>
      <div className="cornerbead-tool-prod-vid-cont">Video</div>
      <div className="cornerbead-tool-prod-vid">
        <ReactPlayer
          url="Drywall Corner Bead Tool V1.mp4"
          width="100%"
          height="auto"
          controls={true}
        />
      </div>
    </body>
  );
}

export default CornerBeadTool;
