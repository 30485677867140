import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from "../Checkout";

import CartProduct from "../CartProduct";

import formatCurrency from "../../Utilities/formatCurrency";

import { Modal } from "react-bootstrap";

import { CartContext } from "../../Context/CartContext";
import { useContext } from "react";

function Header() {
  const initialOptions = {
    "client-id":
      "ATvzeCTnCBVSvEHW9yg3Wx6YIufndTAHj7Dd8OTZKlDmGlJSXwYObSSxXvE0Uj7xUqjo4nWAt5okvjHc",
    currency: "CAD",
    intent: "capture",
  };

  const cart = useContext(CartContext);

  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );

  const [click, setClick] = useState(false);

  const handleClick = () => {
    if (click === false) {
      const scrollBarCompensation =
        window.innerWidth - document.body.offsetWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollBarCompensation}px`;
    } else {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "";
    }
    setClick(!click);
  };

  const closeMobileMenu = () => {
    setClick(false);
    document.body.style.overflow = "unset";
    document.body.style.paddingRight = "";
    window.scrollTo({ top: 0 });
  };

  const hideMenu = () => {
    if (window.innerWidth > 1100) {
      setClick(false);
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "";
    }
  };

  window.addEventListener("resize", hideMenu);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <header>
        <div onClick={handleShow} className="shopping-cart">
          <FontAwesomeIcon
            className="shopping-cart-icon"
            icon={faCartShopping}
          />
          <div className={productsCount > 0 ? "num-items-active" : "num-items"}>
            {productsCount}
          </div>
        </div>
        <div
          className={click ? "menu-icon-active" : "menu-icon"}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={click ? faTimes : faBars} />
        </div>
        <div className="nav-menu-cont">
          <div className={click ? "nav-menu-active" : "nav-menu"}>
            <li className="zero">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Kor-Mos Manufacturing
              </Link>
            </li>
            <li className="first">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className="second">
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                About
              </Link>
            </li>
            <li className="third">
              <Link
                to="/projects"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Projects
              </Link>
            </li>
            <li className="fourth">
              <Link
                to="/contact"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Contact
              </Link>
            </li>
          </div>
        </div>
      </header>
      <Modal className="modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Shopping Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {productsCount > 0 ? (
            <>
              <p>Items in your cart:</p>
              {cart.items.map((currentProduct, idx) => (
                <CartProduct
                  key={idx}
                  id={currentProduct.id}
                  quantity={currentProduct.quantity}
                >
                  {" "}
                </CartProduct>
              ))}

              <h2>
                {" "}
                Subtotal: {formatCurrency(cart.getTotalCost().toFixed(2))}
              </h2>

              <h4>Shipping: {formatCurrency(4.99)}</h4>  
              <h4>GST: {formatCurrency((cart.getTotalCost()+4.99)*0.05)}</h4>
              <hr></hr>
              <h2>Total: {formatCurrency((cart.getTotalCost()+4.99)*1.05)}</h2>
              <PayPalScriptProvider
                options={initialOptions}
                deferLoading={true}
              >
                <Checkout />
              </PayPalScriptProvider>
            </>
          ) : (
            <h2> There are no items in your cart.</h2>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
