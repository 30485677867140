const productsArray = [
    {
        id: "1",
        title: "Taping Tool",
        price: 59.99,
        src: "Drywall-taping-tool.jpg",
    },
    {
        id: "2",
        title: "Corner Bead Tool",
        price: 14.99,
        src: "Drywall-corner-bead-tool.jpg",
    },
    {
        id: "3",
        title: "Brush Saver",
        price: 4.99,
        src: "Brush-saver.PNG",
        images: [{
            id: 0,
            src: "Brush-saver.PNG",
          },
          {
            id: 1,
            src: "Brush-saver2.PNG",
          }],
        sku: 364215376135191
    }
]

function getProductData(id) {
    let productData = productsArray.find(product => product.id === id)

    return productData;
}


export { productsArray, getProductData };